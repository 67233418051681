import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from '@mui/material';
import { ApolloClientFactory } from 'api/Apollo';
import { useEffect, useState } from 'react';
import { LoadingScreen } from 'components/loaders/LoadingScreen';
import { apiUrl } from 'utils/env';
import jwtDecode from 'jwt-decode';

type Token = {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: string[];
  scope: string;
  sub: string;
};

export const AuthenticatedApolloProvider = ({ children }: { children?: React.ReactNode }) => {
  const { getAccessTokenSilently, loginWithRedirect, isLoading, error } = useAuth0();
  const [token, setToken] = useState<string>();
  const [tokenExpiry, setTokenExpiry] = useState<number | null>();

  useEffect(() => {
    if (isLoading) return;

    // The token needs to be fetched asynchronously, so this must be done in a separate async function and called at the bottom
    async function fetchToken() {
      try {
        const authToken = await getAccessTokenSilently();
        const decodedToken = jwtDecode(authToken) as Token;

        setTokenExpiry(decodedToken.exp * 1000);

        setToken(authToken);
      } catch {
        // auth0 throws an error if the user is not authenticated. We catch it and redirect for logging in

        // Provide details about the redirect URI and the pathname to maintain the complete route intended by the user
        loginWithRedirect({
          appState: { target: window.location.pathname },
          authorizationParams: {
            redirect_uri: window.location.origin,
          },
        });
      }
    }

    fetchToken();

    if (tokenExpiry) {
      const timeToExpiry = tokenExpiry - new Date().getTime();
      const refreshBefore = 5 * 60 * 1000; // 5 minutes in milliseconds

      // Set the interval to check a bit before the token expires
      const intervalId = setTimeout(() => {
        fetchToken();
      }, timeToExpiry - refreshBefore);

      // Clean up interval on component unmount
      return () => clearTimeout(intervalId);
    }
  }, [getAccessTokenSilently, isLoading, loginWithRedirect, tokenExpiry]);

  if (error) {
    return <Alert severity='error'>Authentication failed: {error.message}</Alert>;
  }

  if (isLoading || !token) {
    return <LoadingScreen />;
  }

  return (
    <ApolloProvider client={ApolloClientFactory(apiUrl, getAccessTokenSilently)}>
      {children}
    </ApolloProvider>
  );
};
