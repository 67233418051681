import { Permission, useAppSearchQuery } from 'api/generated/graphql';
import { getAssetTypeConfig } from 'rules/asset/getAssetTypeConfig';
import { getAssetUrl } from 'rules/asset/getAssetUrl';
import { getProjectUrl } from 'rules/project/getProjectUrl';
import { getReservationUrl } from 'rules/reservationRules';
import { getOrganizationUl } from 'components/links/OrganizationLink';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { organizationIcon, projectIcon, singleReservationIcon } from 'icons/IconDefinitions';
import { faQuestion } from '@fortawesome/pro-regular-svg-icons';
import { orderBy } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';

enum ResultType {
  Asset = 'Asset',
  Project = 'Project',
  Reservation = 'Reservation',
  Organization = 'Organization',
}

export type SearchResult = {
  id: string;
  title: string;
  type: ResultType;
  url: string;
  icon: IconDefinition;
};

const typeOrder = [
  ResultType.Project,
  ResultType.Asset,
  ResultType.Reservation,
  ResultType.Organization,
];

/**
 * Hook that searches the API across all entities and returns the results as a unified list.
 * @param query
 * @returns an object with the search results and a loading flag.
 */
export const useAppSearch = (query: string) => {
  const maxResultsPerCategory = 5;

  const { hasGlobalPermission } = useCurrentUser();

  // Searching organizations only makes sense if the user has the permission to read them in a global context
  const hasOrganizationPermission = hasGlobalPermission(Permission.ReadOrganization);

  // Until we implement an asset details page for extenal users, we limit the search to assets the user has permission to read
  const hasAssetPermission = hasGlobalPermission(Permission.ReadAsset);

  const { data, previousData, loading } = useAppSearchQuery({
    variables: {
      query,
      limit: maxResultsPerCategory,
    },
    skip: !query.length,
    fetchPolicy: 'no-cache',
  });

  const results: SearchResult[] = [];

  const latestData = data ?? previousData; // use previous data while loading, to avoid flickering

  if (hasAssetPermission) {
    latestData?.assets?.results?.forEach((asset) => {
      results.push({
        id: asset.id,
        title: asset.externalId,
        type: ResultType.Asset,
        url: getAssetUrl(asset),
        icon: asset.assetModel ? getAssetTypeConfig(asset.assetModel).icon : faQuestion,
      });
    });
  }

  data?.projects?.results?.forEach((project) => {
    results.push({
      id: project.id,
      title: project.name,
      type: ResultType.Project,
      url: getProjectUrl(project.id),
      icon: projectIcon,
    });
  });

  data?.reservations?.results?.forEach((reservation) => {
    results.push({
      id: reservation.id,
      title: reservation.name ?? reservation.id,
      type: ResultType.Reservation,
      url: getReservationUrl(reservation.id),
      icon: singleReservationIcon,
    });
  });

  if (hasOrganizationPermission) {
    data?.organizations?.results?.forEach((org) => {
      results.push({
        id: org.id,
        title: org.name,
        type: ResultType.Organization,
        url: getOrganizationUl(org.id),
        icon: organizationIcon,
      });
    });
  }

  // Sort results by exact matches first, then alphabetically
  const sortedResults = orderBy(
    results,
    [
      (result) => -typeOrder.indexOf(result.type), // First criteria: order by type
      (result) => result.title.toLowerCase() === query.toLowerCase(), // Second criteria: exact matches
      'title', // Third criteria: alphabetical order
    ],
    ['desc', 'asc']
  ); // ‘desc’ for true values first in exact matches, ‘asc’ for alphabetical order

  return {
    results: sortedResults,
    loading,
  };
};
