import { ChangeEvent } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';

export type FormTextFieldProps<T extends object> = Omit<TextFieldProps, 'name'> & {
  name: keyof T;
};

export const FormTextField = <T extends object>({ name, ...props }: FormTextFieldProps<T>) => {
  const [field, meta] = useField(name as string);

  const configTextField = {
    ...field,
    ...props,
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (props.type !== 'number' || e.target.value.match(/^[0-9.,-]*$/)) {
        // First run the formik change handler
        field.onChange(e);
        // Then run any external change handler
        if (props.onChange) props.onChange(e);
      }
    },
    fullWidth: true,
    value: field.value || '', // TextField.value should not get null-values. Will trigger warning. For controlled components, empty string should be used
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return <TextField {...configTextField} margin='normal' />;
};
