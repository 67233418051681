import { DefaultPageContainer } from 'components/layout/DefaultPageContainer';
import { TermsAndPolicyBottomNavigation } from 'modules/termsAndPolicyViewer/TermsAndPolicyBottomNavigation';
import { privacyPolicyUrl, termsAndConditionsUrl } from 'api/mocks/termsAndPolicy';
import { Box, Button, Checkbox, Divider, FormControlLabel, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'utils/hooks/useDocumentTitle';
import {
  DocumentType,
  PolicyDocumentSortableField,
  useCreateUserPolicyConsentsMutation,
  useListPolicyDocumentsQuery,
} from 'api/generated/graphql';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { first } from 'lodash';
import { PowersiteLogoFull } from 'icons/PowersiteLogoFull';
import { PowersiteLogoBlack } from 'icons/PowersiteLogoBlack';
import { LogoNavbar } from 'components/layout/Navbar/LogoNavbar';
import { useTheme } from '@mui/material';

type Confirmations = {
  termsAndConditions: boolean;
  privacyPolicy: boolean;
};

const TermsAndPolicyAcceptance = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('POWERSITE'));
  const { currentUser } = useCurrentUser();

  const [createUserPolicyConsents] = useCreateUserPolicyConsentsMutation();

  const displayPrivacyPolicyCheckbox =
    currentUser?.latestTermsAndConditionsAccepted === null &&
    currentUser.latestPrivacyPolicyAccepted === null;

  const { data: policyDocumentsData } = useListPolicyDocumentsQuery({
    variables: {
      filter: {
        documentTypes: [DocumentType.PrivacyPolicy],
        isPublished: true,
      },
      sort: {
        field: PolicyDocumentSortableField.Version,
        asc: false,
      },
      limit: 1,
    },
    skip: !displayPrivacyPolicyCheckbox,
  });

  const { data: termsAndConditionsData } = useListPolicyDocumentsQuery({
    variables: {
      filter: {
        documentTypes: [DocumentType.TermsAndConditions],
        isPublished: true,
      },
      sort: {
        field: PolicyDocumentSortableField.Version,
        asc: false,
      },
      limit: 1,
    },
  });

  const latestPolicyDocument = first(policyDocumentsData?.policyDocuments?.results);
  const latestTermsAndConditionsDocument = first(termsAndConditionsData?.policyDocuments?.results);

  const [confirmations, setConfirmations] = useState<Confirmations>({
    termsAndConditions: false,
    privacyPolicy: false,
  });

  const acceptTermsAndConditions = () => {
    const input = [
      {
        policyDocumentId: latestTermsAndConditionsDocument!.id,
        optionalConsent: confirmations.termsAndConditions,
      },
    ];

    if (displayPrivacyPolicyCheckbox && latestPolicyDocument) {
      input.push({
        policyDocumentId: latestPolicyDocument.id,
        optionalConsent: confirmations.privacyPolicy,
      });
    }

    if (latestTermsAndConditionsDocument) {
      createUserPolicyConsents({
        variables: {
          input,
        },
      });
    }
  };

  const submitButton = (
    <Button
      type='submit'
      color='primary'
      variant='contained'
      size='medium'
      disabled={!confirmations.termsAndConditions}
      fullWidth
      onClick={acceptTermsAndConditions}
    >
      {t('BUTTON_SUBMIT')}
    </Button>
  );

  const privacyPolicyInfo = (
    <Typography variant='body2' pt={2}>
      <Trans
        i18nKey='PRIVACY_POLICY.INFOBOX'
        components={{
          privacyPolicyLink: (
            <Link href={privacyPolicyUrl} target='_blank' pl={0.5} rel='noopener' />
          ),
        }}
      />
    </Typography>
  );

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <>
      <LogoNavbar />
      <DefaultPageContainer>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <Box maxWidth={600}>
            {isDarkMode ? (
              <PowersiteLogoFull sx={{ height: 80, width: 120 }} />
            ) : (
              <PowersiteLogoBlack sx={{ height: 80, width: 120 }} />
            )}
            <Typography variant='h2'>{t('POWERSITE.WELCOME')}</Typography>
            <Typography variant='body2' pt={2}>
              {displayPrivacyPolicyCheckbox
                ? t('TERMS_AND_CONDITIONS.AGREE_INFOBOX')
                : t('TERMS_AND_CONDITIONS.UPDATES_INFOBOX')}
            </Typography>
            <Link href={termsAndConditionsUrl} target='_blank' rel='noopener'>
              <Typography variant='body2' mt={2}>
                {t('TERMS_AND_CONDITIONS.READ')}
              </Typography>
            </Link>
            <FormControlLabel
              sx={{ my: 3 }}
              control={
                <Checkbox
                  value={confirmations.termsAndConditions}
                  onChange={(e, val) =>
                    setConfirmations((prevState) => ({
                      ...prevState,
                      termsAndConditions: val,
                    }))
                  }
                />
              }
              label={
                <Typography variant='body1'>{t('TERMS_AND_CONDITIONS.CONFIRMATION')}</Typography>
              }
            />

            {displayPrivacyPolicyCheckbox ? (
              <>
                <Divider />
                {privacyPolicyInfo}

                <FormControlLabel
                  sx={{ my: 3 }}
                  control={
                    <Checkbox
                      value={confirmations.privacyPolicy}
                      onChange={(e, val) =>
                        setConfirmations((prevState) => ({
                          ...prevState,
                          privacyPolicy: val,
                        }))
                      }
                    />
                  }
                  label={
                    <Typography variant='body2'>{t('PRIVACY_POLICY.CONFIRMATION')}</Typography>
                  }
                />
                {submitButton}
              </>
            ) : (
              <>
                {submitButton}
                <Divider />
                {privacyPolicyInfo}
              </>
            )}
          </Box>
        </Box>
        <TermsAndPolicyBottomNavigation
          documentType={null}
          onTabChange={(val: DocumentType) => {
            if (val === DocumentType.PrivacyPolicy) window.open(privacyPolicyUrl, '_blank');
            if (val === DocumentType.TermsAndConditions)
              window.open(termsAndConditionsUrl, '_blank');
          }}
        />
      </DefaultPageContainer>
    </>
  );
};

export default TermsAndPolicyAcceptance;
