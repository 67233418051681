import { useState } from 'react';
import { Autocomplete, CircularProgress, Stack, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  OrganizationBasicsFragment,
  OrganizationSortableField,
  useListOrganizationsQuery,
} from 'api/generated/graphql';
import { QueryParamKeys } from 'enums/queryParamKeys';
import { useQueryFilters } from 'utils/hooks/useQueryFilters';
import { Sx } from 'types/Sx';
import { mergeSx } from 'utils/mui';

type OrganizationSelectProps = {
  value?: string;
  onChange?: (organization: OrganizationBasicsFragment | null) => void;
  textFieldProps?: TextFieldProps;
  enableQueryParams?: boolean;
  sx?: Sx;
};

export const OrganizationSelect = ({
  value,
  onChange,
  textFieldProps,
  enableQueryParams = false,
  sx,
}: OrganizationSelectProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { data, loading } = useListOrganizationsQuery({
    skip: !open && !value,
    variables: {
      sort: [{ field: OrganizationSortableField.Name, asc: true }],
    },
  });

  const organizations = data?.organizations?.results ?? [];

  const selectedOrganization = organizations.find((c) => c.id === value) ?? null;
  const { setQueryParams } = useQueryFilters();
  return (
    <Autocomplete
      fullWidth
      value={selectedOrganization}
      options={organizations}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      sx={mergeSx({ minWidth: 200 }, sx)}
      renderInput={(params) => (
        <TextField
          margin='normal'
          name='organization'
          label={
            <Stack direction='row' alignItems='center'>
              {t('ORGANIZATION_one')}
              {loading && (
                <CircularProgress size={18} sx={{ ml: 2 }} aria-label='circular progress' />
              )}
            </Stack>
          }
          {...textFieldProps}
          {...params}
        />
      )}
      renderOption={(props, option) => (
        <li data-testid='organization-option' {...props} key={option.id}>
          {option.name}
        </li>
      )}
      getOptionLabel={(organization) => organization.name}
      onChange={(e, value) => {
        if (onChange) onChange(value);

        if (enableQueryParams) {
          setQueryParams({
            [QueryParamKeys.ORGANIZATION_ID]: value?.id,
          });
        }
      }}
    />
  );
};
